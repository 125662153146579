
import { Component, Vue, Provide, Watch, Emit } from 'vue-property-decorator'
import httpHelper from '@/utils/request'
import configs from '@/configs'
import { setMBankAuth } from '@/utils/cookies'
import GlobalMixin from '@/mixins/global'
import getCurrentQuery from '@/utils/querystrings'
import '@/utils/bcm.min'

@Component({
  name: 'bcmbank',
  mixins: [GlobalMixin]
})
export default class PartnerLoginPage extends Vue {
    @Provide() public showLogin: boolean = false;

    public async created () {
      if (!getCurrentQuery('redirect')) { // 配置不带redirect前端自己手动重定向加
        this.setUrl()
      }
      this.getOpenId()
    }

    @Emit()
    public async next () {
      setMBankAuth()
      this.getOpenId()
    }

    @Emit()
    public setUrl () {
      location.replace(
        `${configs.passportRoot}bcmbank?redirect=https%3A%2F%2Fm.benlai.com`
      )
    }

    @Emit()
    public getOpenId () {
      const that = this
      window.BCM.init({}, function () {
        window.BCM.Native.BCMApi('xpGetUserOpenid', {}, function (openRes:any) {
          that.getApplets(openRes.openid)
        })
      })
    }

    @Emit()
    public getPhone (openid: any, appletKey: any, appletSign: any, appletRequest: any) {
      const that = this
      try {
        window.BCM.Native.BCMApi('xpGetMobile', { appletSign: appletSign, appletKey: appletKey, appletRequest: appletRequest }, function (res:any) {
          if (res.code !== '0') {
            if (res.msg === '获取手机号出错' || res.msg === '获取手机号异常') {
              that.doLogin(openid)
            } else {
              window.BCM.Native.BCMApi('xpCloseTinyApp', {})
            }
          } else {
            let { appletKey, appletSign, appletResponse } = res
            that.doAuth(openid, appletKey, appletSign, appletResponse)
          }
        })
      } catch (err) {
        console.log('getPhoneErr', err)
      }
    }

    @Emit()
    public async getApplets (openid: any) {
      try {
        const res = await httpHelper.get({
          url: 'ThirdLogin/Sdk',
          data: {
            parameter: openid,
            identity: 'http://bankbcm.benlai.com'
          }
        })
        if (res.code === 0) {
          console.log(JSON.parse(res.value.expand.key))
          let { appletKey, appletSign, appletRequest } = JSON.parse(res.value.expand.key)
          this.getPhone(openid, appletKey, appletSign, appletRequest)
        }
      } catch (err) {
        console.log('err', err)
        this.$toasted.show(err.msg || err.message)
      }
    }
    @Emit()
    public async doAuth (openid: any, appletKey: any, appletSign: any, appletResponse: any) {
      const data: any = {
        openid: openid,
        appletKey: appletKey,
        appletSign: appletSign,
        appletResponse: appletResponse,
        needCheck: 0,
        grant_type: 'Auth_BankBCM',
        RegistSource: 2
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/bankbcm',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        if (res.value.extend.benlaiZTHTML) {
          location.replace(res.value.extend.benlaiZTHTML)
        } else {
          this.goTo(res, 9, 11)
        }
      } catch (err) {
        this.goTo(err, 9, 11)
        this.$toasted.show(err.msg || err.message)
      }
    }
    @Emit()
    public async doLogin (openid: any) {
      const data: any = {
        openid: openid,
        grant_type: 'Auth_BankBCM',
        needCheck: 1,
        RegistSource: 2
      }
      try {
        const res = await httpHelper.post({
          url: 'authorize/bankbcm',
          contentType: 'application/x-www-form-urlencoded',
          data: data,
          headers: {
            Authorization: configs.basicAuthorization
          }
        })
        if (res.value.extend.benlaiZTHTML) {
          location.replace(res.value.extend.benlaiZTHTML)
        } else {
          this.goTo(res, 9, 11)
        }
      } catch (err) {
        this.goTo(err, 9, 11)
        this.$toasted.show(err.msg || err.message)
      }
    }
}
